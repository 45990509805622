import React from 'react'
import styled from "styled-components"
import {graphql, StaticQuery} from "gatsby"
import {Row, Col, Container} from 'reactstrap'
import ReactCompareImage from 'react-compare-image'
import Quote from 'components/shared/Quote'
import {media} from "utils/Media"

const LineUp = styled.div`
    position: relative;
    margin: -4rem 0 0;
    padding-top: 1rem;
    
    @media ${media.sm} {
        background: url(${props => props.cloudsSm}) no-repeat left top;
        background-size: contain;
        margin: -4rem 0 -4rem;
        padding-top: 4rem;
    }    
    
    @media ${media.md} {
        background: url(${props => props.cloudsLg}) no-repeat left top;
        background-size: contain;
        margin: -4rem 0 -6rem;
    }    
    
    @media ${media.lg} {
        margin: -4rem 0 -3rem;
    }    
    
    @media ${media.xl} {
        margin: -4rem 0 -5rem;
    }    
`

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                CloudsSm: file(relativePath: { eq: "Article1/clouds-top-991.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 991, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                
                CloudsLg: file(relativePath: { eq: "Article1/clouds-top-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1920, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                Anna: file(relativePath: { eq: "Article1/anna-lineup.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 768, quality: 100)
                    }
                }
                Hans: file(relativePath: { eq: "Article1/hans-lineup.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 768, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <LineUp
                cloudsSm={data.CloudsSm.childImageSharp.gatsbyImageData.images.fallback.src}
                cloudsLg={data.CloudsLg.childImageSharp.gatsbyImageData.images.fallback.src}
            >
                <Container fluid={true}>
                    <Container>
                        <Row>
                            <Col xs={12} lg={6}>
                                <h4 className="mb-4">Costumes like Anna’s coronation dress (a silk taffeta with enhanced dimensional embroidery in satin and organza) reflect a classic Norwegian style of ‘bunad’. So, too, does the outfit worn by Prince Hans, who is the “most classically Disney character in the piece,” says Oram. “He’s a handsome prince in a fur coat – as many princes are – but the detailing is specific to Norway.”</h4>

                                <h4 className="font--regular mb-4">Today, the classic bunad style is reserved for special occasions.</h4>

                                <h4 className="font--regular mb-4">“Holidays and marriages,” says Morten Sohlberg, restaurateur at the Nordic cultural centre, Scandinavia House, in New York. “Traditionally, they weren’t white wedding dresses. The bunads were the costumes for weddings, as they still are in Norway and in other Scandinavian countries.</h4>

                                <h4 className="font--regular mb-4">“But they’re extremely different from area to area, region to region. So, you might be just a few kilometres away, and you are in another county, so to speak, and their bunad would be almost not recognizable. Totally different patterns, totally different colours and designs overall, just by being in a different town or city or county.”</h4>
                            </Col>
                            <Col xs={12} lg={6}>
                                <ReactCompareImage sliderLineColor="#8AECF5" sliderLineWidth={1} handleSize={0} sliderPositionPercentage={0} hover={true} leftImage={data.Hans.childImageSharp.gatsbyImageData.images.fallback.src} rightImage={data.Anna.childImageSharp.gatsbyImageData.images.fallback.src} />
                                <Quote>
                                    <div className="quoteContent">
                                        <h3>Traditionally, they weren’t white wedding dresses. The bunads were the costumes for weddings</h3>
                                    </div>
                                </Quote>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </LineUp>
        )}
    />
)

export default Query;