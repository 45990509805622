import React, {Component} from "react"
import {StaticQuery, graphql} from "gatsby"
import {Container} from "reactstrap"
import styled from "styled-components"
import HeadingSpecial from "components/shared/HeadingSpecial"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import {media} from "utils/Media"
import {Player, ControlBar, LoadingSpinner, BigPlayButton} from 'video-react';
import videoPosterSm from 'images/video-poster-sm.jpg'
import videoPosterMd from 'images/video-poster-md.jpg'
import videoPoster from 'images/video-poster-xl.jpg'
import videoSm from 'videos/frozen-norway-991.mp4'
import video from 'videos/frozen-norway-1920.mp4'
import "video-react/dist/video-react.css"
import videoGif from 'videos/frozen_norway_576_boomerang.gif'

const Relative = styled.div`
    position: relative;
    
    &.flex {
        @media ${media.sm} {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
        }
    }
`

const VideoContentWrapper = styled.div`
    width: 100%;
    position: absolute;
    bottom: 3rem;
    left: 0;
    z-index: 3;
`

const ImgTop = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
`

const ImgBottom = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 2;   
`

const VideoContent = styled.div`
    width: 100%;
    max-width: 960px;
    
    @media ${media.sm} {
        color: ${props => props.theme.colors.white};
    }
    
    @media ${media.md} {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 40px;
    }
    
    @media ${media.lg} {
        bottom: 80px;
    }
    
    .btn {
        margin: 2rem auto 0;
    }
`

const MapQuery = () => (
    <StaticQuery
        query={graphql`
            query {
                BgMapXs: file(relativePath: { eq: "bg-map-575.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 575, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                
                BgMapSm: file(relativePath: { eq: "bg-map-768.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 768, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                
                BgMapLg: file(relativePath: { eq: "bg-map-1200.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1200, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                
                BgMapXl: file(relativePath: { eq: "bg-map-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1920, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                ForegroundTop: file(relativePath: { eq: "foreground-top.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1920, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                ForegroundBottom: file(relativePath: { eq: "foreground-bottom.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1920, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
		`}
        render={data => (
            <Map data={data}/>
        )}
    />
)

class Map extends Component {
    constructor(props) {
        super(props);

        this.state = {
            videoPoster: '',
            video: ''
        }
    }

    componentDidMount() {
        this.changeVideo();
        window.addEventListener('resize', this.changeVideo, true);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.changeVideo, true);
    }

    changeVideo = () => {
        const width = window.innerWidth;
        const newState = this.state

        if (width < 992) {
            newState.video = videoSm
        } else {
            newState.video = video
        }

        if (width > 991) {
            newState.videoPoster = videoPoster
        }

        if (width < 992 && width > 575) {
            newState.videoPoster = videoPosterMd
        }
        if (width < 576) {
            newState.videoPoster = videoPosterSm
        }

        this.setState(newState);
    }

    render() {
        return (
            <Relative className="mb-4">
                <ImgTop className="">
                    <GatsbyImage image={this.props.data.ForegroundTop.childImageSharp.gatsbyImageData} alt="" title=""
                         className="img-fluid"/>
                </ImgTop>
                <div className="d-block d-sm-none">
                </div>

                <Player
                    playsInline
                    autoPlay
                    muted
                    loop
                    preload="auto"
                    poster={this.state.videoPoster}
                    src={this.state.video}
                    ref={(videoPlayer) => this.videoPlayer = videoPlayer}
                    className="d-none d-sm-block"
                >
                    <ControlBar disableCompletely={true} className="my-class"/>
                    <LoadingSpinner className="d-none"/>
                    <BigPlayButton className="d-none"/>
                </Player>
                <img src={videoGif} alt="" style={{borderBottom: '1px solid white'}} className="img-fluid d-sm-none"/>
                <VideoContentWrapper>
                    <Container fluid={true} className="px-0 px-sm-2">
                        <Container>
                            <Relative className="flex">
                                <VideoContent>
                                    <HeadingSpecial lines={false} headingTag="h1" className="py-2 py-xl-5 font--bigger color--white shadow--navy"
                                                    title="Bringing FROZEN to Life<br/>Norwegian-style"/>
                                </VideoContent>
                            </Relative>
                        </Container>
                    </Container>
                </VideoContentWrapper>
                <ImgBottom className="">
                    <GatsbyImage image={this.props.data.ForegroundBottom.childImageSharp.gatsbyImageData} alt="" title=""
                         className="img-fluid"/>
                </ImgBottom>
            </Relative>
        )
    }
}

export default MapQuery