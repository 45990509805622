import React, {Component} from 'react'
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import styled from "styled-components"
import HeadingSpecial from "components/shared/HeadingSpecial"
import shapeImg from "images/Article1/norwegian-wood-bg.png"
import {graphql, StaticQuery} from "gatsby"
import {media} from "utils/Media"

const Relative = styled.div`
    position: relative;
`

const ImageWrapper = styled.div`
    position: relative;
    z-index: 1;
`

const ShapeWrapper = styled.div`
    padding: 0 1rem 0;
    
    @media ${media.sm} {
        padding: 0;
        position: absolute;
        top: 75%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
    }
`

const Shape = styled.div`
    @media ${media.sm} {
        width: 440px;
        height: 135px;
        padding: 1rem;
        background: url(${shapeImg}) no-repeat center center;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    @media ${media.md} {
        width: 580px;
        height: 178px;
        padding: 2rem;
    }
`

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                BgNorwegianWoodXs: file(relativePath: { eq: "Article1/norwegian-wood-576.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 575, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                
                BgNorwegianWoodSm: file(relativePath: { eq: "Article1/norwegian-wood-768.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 768, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                
                BgNorwegianWoodMd: file(relativePath: { eq: "Article1/norwegian-wood-991.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                BgNorwegianWoodLg: file(relativePath: { eq: "Article1/norwegian-wood-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                ForegroundTop: file(relativePath: { eq: "foreground-top.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                ForegroundBottom: file(relativePath: { eq: "foreground-bottom.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
               
            }
		`}
        render={data => (
            <NorwegianWood data={data}/>
        )}
    />
)

class NorwegianWood extends Component {
    render() {
        return (
            <Relative className="my-5">
                <Relative>
                    <ImageWrapper>
                        <GatsbyImage image={this.props.data.BgNorwegianWoodXs.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="img-fluid d-block d-sm-none"
                        />
                        <GatsbyImage image={this.props.data.BgNorwegianWoodSm.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="img-fluid d-none d-sm-block d-md-none"
                        />
                        <GatsbyImage image={this.props.data.BgNorwegianWoodMd.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="img-fluid d-none d-md-block d-lg-none"
                        />
                        <GatsbyImage image={this.props.data.BgNorwegianWoodLg.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="img-fluid d-none d-lg-block"
                        />
                    </ImageWrapper>
                </Relative>
                <ShapeWrapper>
                    {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeInUp">*/}
                        <Shape>
                            <HeadingSpecial lines={false} headingTag="h2" className="" title="Norwegian wood"/>
                        </Shape>
                    {/*</ScrollAnimation>*/}
                </ShapeWrapper>
            </Relative>
        );
    }
}

export default Query;