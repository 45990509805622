import React from "react"
import {StaticQuery, graphql} from "gatsby"
import styled from "styled-components"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import 'components/shared/GatsbyImage'
import {Container, Row, Col} from "reactstrap"
import {media} from "utils/Media"
import olafImgXs from 'images/Article1/church-320.png'

const Relative = styled.div`
    position: relative;
`

const ContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    margin: -10rem 0 7rem;
    padding: 12rem 0 4rem;
    overflow: hidden;  
    
    @media ${media.sm} {
        background: url(${props => props.imageSm}) no-repeat right -40px top 620px;
        background-size: 100%;
        min-height: 190vw;
        margin: -10rem 0 3rem;
    }
    
    @media ${media.md} {
        background: url(${props => props.imageMd}) no-repeat right -80px top 500px;
        background-size: 100%;
        min-height: 160vw;
    }
    
    @media ${media.lg} {
        min-height: 1740px;
         background: url(${props => props.imageLg}) no-repeat right bottom;
        background-size: 1130px;
    }
    
    @media ${media.xl} {
        background: url(${props => props.imageXl}) no-repeat right center;
        background-size: contain;
        min-height: initial;
    }
    
    img {
        margin-right: -30px;
        width: 320px;
        max-width: initial;
    }
    
    .narrow {
        max-width: 300px;
    }
    
    .narrow-sm {
        @media (max-width: 767px) {
            max-width: 300px;
        }
    }
    
    .st-olaf {
        font-family: ${props => props.theme.font.family.special} !important;
        color: ${props => props.theme.colors.navy};
        font-size: 1.875rem;
        margin: 1rem 0 1rem !important;
        
        @media ${media.md} {
            margin: 4rem 0 2rem !important;
            font-size: 2.5rem;
        }
        
        @media ${media.md} {
            margin: 3rem 0 2rem !important;
            font-size: 4rem;
        }
    }
    
    .balestrand {
        font-family: ${props => props.theme.font.family.special} !important;
        color: ${props => props.theme.colors.navy};
        margin-bottom: 1rem !important;
        font-size: 1.25rem;
        
        @media ${media.md} {
            margin-bottom: 3rem !important;
            font-size: 1.5rem;
        }
        
        @media ${media.md} {
            margin: 3rem 0 2rem !important;
            font-size: 2rem;
        }
    }  
`

const StOlaf = ({children}) => (
    <StaticQuery
        query={graphql`
       
			query {
                BgStOlafSm: file(relativePath: { eq: "Article1/church-768.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 768, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                
                BgStOlafMd: file(relativePath: { eq: "Article1/church-991.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 991, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                
                BgStOlafLg: file(relativePath: { eq: "Article1/church-1200.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1200, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                
                BgStOlafXl: file(relativePath: { eq: "Article1/church-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1920, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
			}
    	`}
        render={data => (
                <ContentWrapper
                    imageSm={data.BgStOlafSm.childImageSharp.gatsbyImageData.images.fallback.src}
                    imageMd={data.BgStOlafMd.childImageSharp.gatsbyImageData.images.fallback.src}
                    imageLg={data.BgStOlafLg.childImageSharp.gatsbyImageData.images.fallback.src}
                    imageXl={data.BgStOlafXl.childImageSharp.gatsbyImageData.images.fallback.src}>
                    <Container fluid={true}>
                        <Container>
                            <Row>
                                <Col xs={12} xl={6}>
                                    {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeIn">*/}
                                        <h4 className="mb-4 ">Set pieces, too, in “FROZEN” reflect Scandinavian architecture,
                                            especially in the wooden-like structures and the staves on the castle where Elsa and
                                            Anna live.</h4>

                                        <h4 className="mb-4 font--regular">“With the architecture in Norway, it’s hundreds and
                                            hundreds of years old. These churches have been around since the first millennium,”
                                            says Oram, who also designed the sets for the Broadway show. “There are wooden
                                            structures that have kind of warped and twisted and aged with time. And every year
                                            they’re painted with tar, which paints them black for kind of the first half of the
                                            year. Then, over the summer season, the tar melts and it kind of stains away. The
                                            wood becomes golden brown underneath it. ”</h4>

                                        <h4 className="mb-4 font--regular">It’s that mystical element that challenged Oram. “I
                                            needed to translate that onto the stage in a way that people understand this stuff
                                            has been around for a long time. This is ancient, solid architecture, which would
                                            then subvert, because obviously you know you have a magical element that comes into
                                            it and changes the nature of the piece. ... There’s nothing like that anywhere else
                                            in the world.”</h4>
                                    {/*</ScrollAnimation>*/}

                                    {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeInRight">*/}
                                        <img
                                            src={olafImgXs}
                                            alt=""
                                            title=""
                                            className="d-block ml-auto d-sm-none"
                                        />

                                        <h4 className="mb-4 st-olaf font--regular">St Olaf’s Church</h4>
                                        <h4 className="mb-4 balestrand font--regular">Balestrand, Norway</h4>

                                        <h4 className="mt-4 font--regular narrow">This church was the inspiration for the chapel in Elsa’s coronation in the movie.</h4>
                                    {/*</ScrollAnimation>*/}
                                </Col>
                                <Col xs={12} lg={6}>
                                </Col>
                            </Row>
                            {/*<ScrollAnimation animateOnce={true} delay={200} animateIn="fadeIn">*/}
                                <p className="mt-4 mb-0 narrow-sm">Content originated and adapted from <a href="http://cuebroadway.com/" target="_blank" rel="noreferrer noopener">CUE&nbsp;BROADWAY</a>.</p>
                            {/*</ScrollAnimation>*/}
                        </Container>
                    </Container>
                </ContentWrapper>
        )}

    />
)

export default StOlaf