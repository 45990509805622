import React, {Component} from "react"
import {Container} from 'reactstrap'
import Layout from "components/Layout/Layout"
import NorwegianVideo from "components/Article1Page/NorwegianVideo"
import ChristopherOram from "components/Article1Page/ChristopherOram"
import LineUp from "components/Article1Page/LineUp"
import NorwegianWood from "components/Article1Page/NorwegianWood"
import Staves from "components/Article1Page/Staves"
import StOlaf from "components/Article1Page/StOlaf"
import FindOutMoreNorwegian from "components/shared/FindOutMoreNorwegian"


import {graphql, StaticQuery} from "gatsby"
import styled from "styled-components"
import {media} from "utils/Media"

const Clouds = styled.div`
    @media ${media.sm} {
        background: url(${props => props.CloudsLg}) no-repeat right center;
        background-size: contain;
    }
`

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                CloudsSm: file(relativePath: { eq: "Article1/clouds-middle-991.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                CloudsLg: file(relativePath: { eq: "Article1/clouds-middle-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Article1Page data={data}/>
        )}
    />
)

class Article1Page extends Component {
    render() {

        return (
            <Layout slug="bringing-frozen-to-life" title="Bringing FROZEN to Life" description="Norwegian style: Discover the inspiration behind bringing Frozen the Musical to life on stage at London’s Theatre Royal Drury Lane" pageName="bringing-frozen-to-life">
                 {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeIn">*/}
                    <NorwegianVideo/>
                {/*</ScrollAnimation>*/}

                <Container fluid={true} className="narrow">
                    <Container>
                         {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeIn">*/}
                            <h4 className="mb-4">A journey through the traditional fashion, art, culture and ancient architecture of Norway cast quite a spell over the creative team tasked with recreating the world of FROZEN on stage.</h4>
                            <h4 className="font--regular">When costume and set designer Christopher Oram began transforming the animated outfits from the film for stage, he reimagined the iconic costumes with something familiar and something new. “There’s a lot of embroidery in Norwegian work, and a lot of the rosemåling. It’s all floral and decorative,” he says. “So we’ve incorporated a lot of that into the visuals of the show. We based Elsa’s magic on that, too. When she controls her magic, she has a sense of floral quality about it.”</h4>
                        {/*</ScrollAnimation>*/}
                    </Container>
                </Container>

                 {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeIn">*/}
                    <ChristopherOram/>
                {/*</ScrollAnimation>*/}

                 {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeIn">*/}
                    <LineUp/>
                {/*</ScrollAnimation>*/}

                 {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeIn">*/}
                    <NorwegianWood/>
                {/*</ScrollAnimation>*/}

                <Clouds
                    CloudsSm={this.props.data.CloudsSm.childImageSharp.gatsbyImageData.images.fallback.src}
                    CloudsLg={this.props.data.CloudsLg.childImageSharp.gatsbyImageData.images.fallback.src}
                >
                     {/*<ScrollAnimation animateOnce={true} delay={0} duration={2} animateIn="zoomIn">*/}
                        <Staves/>
                    {/*</ScrollAnimation>*/}

                    <StOlaf/>
                </Clouds>

                <FindOutMoreNorwegian title="Find out more"/>
            </Layout>
        )
    }
}

export default Query