import React, {Component} from 'react'
import HeadingSpecial from "components/shared/HeadingSpecial"
import {Container, Row, Col} from "reactstrap"
import VideoButton from "components/shared/VideoButton"
import VideoModal from "components/shared/VideoModal"
import styled from "styled-components"
import {graphql, StaticQuery, Link} from "gatsby"
import {media} from "utils/Media"


import {GatsbyImage, getImage} from "gatsby-plugin-image";

const Clouds = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    // background: url(${props => props.CloudsSm}) no-repeat right center;
    // background-size: cover;
    margin-top: -6rem;
    margin-bottom: -2rem;
    
    @media ${media.sm} {
        height: 350px; 
    }
    
    @media ${media.md} {
        height: 520px;
        margin-top: -8rem;
        margin-bottom: -6rem;
    }
    
    @media ${media.lg} {
        // background: url(${props => props.CloudsLg}) no-repeat right center;
        //background-size: cover;
        height: 800px;
        margin-top: -7rem;
        margin-bottom: -8rem;
    }
    
    a {
        display: block;
        margin: 2.5% auto;
    }
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                CloudsSm: file(relativePath: { eq: "Article1/clouds-bottom-991.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                CloudsLg: file(relativePath: { eq: "Article1/clouds-bottom-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                videoPlaceholder1: file(relativePath: { eq: "let-it-go-article-thumb-640.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 540, quality: 100)
                    }
                }
                
                videoPlaceholder2: file(relativePath: { eq: "making-frozen-the-musical-thumb.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 540, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <FindOutMore title={props.title} data={data}/>
        )}
    />
)

class FindOutMore extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalOpen: false,
        }
    }
    
    toggleModal = ( title, src) => {
        
        this.setState({
            src: src,
            title: title,
            modalOpen: !this.state.modalOpen
        });
    }

    render() {
        return (
            <Clouds
                CloudsSm={this.props.data.CloudsSm.childImageSharp.gatsbyImageData.images.fallback.src}
                CloudsLg={this.props.data.CloudsLg.childImageSharp.gatsbyImageData.images.fallback.src}
            >
                <Container fluid={true} className="pb-5">
                    <Container>
                         {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeInUp">*/}
                            <HeadingSpecial headingTag="h2" className="mb-4" title={this.props.title}/>
                        {/*</ScrollAnimation>*/}
                        {/* <ScrollAnimation animateOnce={true} delay={500} animateIn="fadeIn">*/}
                            <Row>
                                <Col xs={12} sm={6}>
                                    <h4 className="d-none d-xl-block">What makes ‘Let It Go’ so catchy?</h4>
                                    <h4 className="d-block d-xl-none">Let It Go</h4>
                                    <Link to="/let-it-go/">
                                        <GatsbyImage image={this.props.data.videoPlaceholder1.childImageSharp.gatsbyImageData}
                                            alt="What makes ‘Let It Go’ so catchy?"
                                            title="What makes ‘Let It Go’ so catchy?"
                                            className="img-fluid d-block"
                                        />
                                    </Link>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <h4>Making Frozen the Musical</h4>
                                    <VideoButton onClick={() => this.toggleModal( "Making Frozen the Musical","https://secure.disney.com/embed/58c27d0acc3c9c895734fa28?domain=www.disney.co.uk")} aria-label="Open video">
                                        <GatsbyImage image={this.props.data.videoPlaceholder2.childImageSharp.gatsbyImageData}
                                            alt="Making Frozen the Musical"
                                            title="Making Frozen the Musical"
                                            className="img-fluid d-block"
                                        />
                                    </VideoButton>
                                </Col>
                            </Row>
                        {/*</ScrollAnimation>*/}
                    </Container>
                    <VideoModal toggleModal={this.toggleModal} title={this.state.title} src={this.state.src} modalOpen={this.state.modalOpen}/>
                </Container>
            </Clouds>
        );
    }
}

export default Query;