import React from "react"
import {StaticQuery, graphql} from "gatsby"
import styled from "styled-components"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import 'components/shared/GatsbyImage'
import Quote from 'components/shared/Quote'
import {Container} from "reactstrap"
import {media} from "utils/Media"

const Relative = styled.div`
    position: relative;
    padding-top: 100px;
`

const QuoteWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    
    @media ${media.sm} {
        top: -3rem;
    }
    
    @media ${media.lg} {
        top: -6rem;
    }
    
    .quote {
        max-width: 540px;
        width: 100%;
    }
`

const Staves = ({children}) => (
    <StaticQuery
        query={graphql`
       
			query {
				BgStavesXs: file(relativePath: { eq: "Article1/staves-576.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 575, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                
                BgStavesSm: file(relativePath: { eq: "Article1/staves-991.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 991, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                
                BgStavesLg: file(relativePath: { eq: "Article1/staves-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 1920, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
			}
    	`}
        render={data => (
            <Relative>
                <GatsbyImage image={data.BgStavesXs.childImageSharp.gatsbyImageData}
                    alt=""
                    title=""
                    className="img-fluid d-block d-sm-none"
                />
                <GatsbyImage image={data.BgStavesSm.childImageSharp.gatsbyImageData}
                    alt=""
                    title=""
                    className="img-fluid d-none d-sm-block d-lg-none"
                />
                <GatsbyImage image={data.BgStavesLg.childImageSharp.gatsbyImageData}
                    alt=""
                    title=""
                    className="img-fluid d-none d-lg-block"
                />
                <QuoteWrapper>
                    <Container fluid={true}>
                        <Container>
                            {/*<ScrollAnimation animateOnce={true} delay={0} animateIn="fadeInLeft">*/}
                                <Quote className="quote">
                                    <div className="quoteContent">
                                        <h3>These churches have been around since the first millennium</h3>
                                    </div>
                                </Quote>
                            {/*</ScrollAnimation>*/}
                        </Container>
                    </Container>
                </QuoteWrapper>
            </Relative>
        )}
    />
)

export default Staves